import waitForElementTransition from "wait-for-element-transition";

import Swiper from "swiper/bundle";

import Rellax from "rellax/rellax";

//lightbox for bootrap 5 https://trvswgnr.github.io/bs5-lightbox/

import Lightbox from "bs5-lightbox";

// import styles bundle
import "swiper/css/bundle";

require("./javascript/configurator");

const options = {
    keyboard: true,
    size: "fullscreen",
};

document.querySelectorAll(".product-image-toggle").forEach((el) =>
    el.addEventListener("click", (e) => {
        e.preventDefault();
        const lightbox = new Lightbox(el, options);
    })
);

jQuery(document).ready(function ($) {
    if ($(".single_variation_wrap").length > 0) {
        $(".single_variation_wrap").on(
            "show_variation",
            function (event, variation) {
                if (variation.price_html) {
                    console.log(variation);
                    var price = variation.price_html;
                    if (price != "") {
                        $("#woo-product .price").hide();
                        $("#woo-product .ajax-price").show().html(price).css({
                            "margin-top": "40px",
                            "margin-bottom": "40px",
                        });
                    }
                }
            }
        );
        $(".single_variation_wrap").on("hide_variation", function () {
            // Handle UI updates for when no valid variation is found
            $("#woo-product .price").show();
            $("#woo-product .ajax-price").hide().css({
                "margin-top": "0px",
                "margin-bottom": "0px",
            });
        });
    }

    var rellax = new Rellax(".rellax");

    function relaxwindow() {
        if (window.innerWidth < 768) {
            rellax.destroy();
        }
    }

    if ($(".rellax").length > 0) {
        relaxwindow();
    }

    $("body").on("click", ".ccc", function (event) {
        event.preventDefault();
        CookieControl.open();
    });

    //apply coupon ajax on checkout
    $("body").on("click", "#checkout_apply_coupon", function (event) {
        event.preventDefault();

        var code = $("#checkout_coupon_code").val();

        var buttonspan = $("#checkout_apply_coupon >span");
        var button = $("#checkout_apply_coupon");

        buttonspan.css("display", "none");
        button.append('<span class="applying">...</span>');

        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: "coupon",
                coupon_code: code,
            },
        })
            .done(function (response) {
                setTimeout(function () {
                    //reload with ajax
                    $(document.body).trigger("update_checkout");
                    $("span.applying").remove();
                    buttonspan.css("display", "block");
                }, 2000);
            })
            .fail(function () {})
            .always(function () {});
    });

    $("#header nav li.menu-item-has-children").click(function (e) {
        if (!$(this).hasClass("open") && $(window).width() < 992) {
            e.preventDefault();
            $(this).parent().find(".open ul").slideUp();
            $(this).parent().find(".open").removeClass("open");
            $(this).addClass("open").find("ul").slideDown();
        }
    });

    $("#header #menu-toggle").click(function () {
        $(this).toggleClass("open");
        $("#header nav .open").removeClass("open");
        $("#header nav").stop().slideToggle();
    });

    $(".woocommerce-MyAccount-navigation li.is-active").click(function (e) {
        if ($("li.is-active").is(e.target)) {
            $(this).parent().toggleClass("open");
        }
    });

    //menu
    $("body").on("click", ".mobileMenu", function () {
        $(".fixedContainer").toggleClass("pointer");

        if ($(".fixedContainer").hasClass("openFixed")) {
            $("#page").toggleClass("mobileOpen");
            $(".menuContainer").toggleClass("active");
            $(this).toggleClass("open");
            $(".menuContainer .menu").toggleClass("show");
            // $(".fixedContainer").style("display", "none");
        } else {
            $(".fixedContainer").show("fast", function () {
                $(".fixedContainer").addClass("openFixed");
                $("#page").toggleClass("mobileOpen");
                $(".menuContainer").toggleClass("active");
                $(".menuContainer .menu").toggleClass("show");

                $(".mobileMenu").toggleClass("open");
            });
        }
    });

    $("body").on("click", ".mobileMemberMenu", function () {
        $(".fixedContainerMembers").toggleClass("pointer");

        if ($(".fixedContainerMembers").hasClass("openFixed")) {
            $("#page").toggleClass("mobileOpen");
            $(".menuContainerMembers").toggleClass("active");
            $(this).toggleClass("open");
            $(".menuContainerMembers .menuMembers").toggleClass("show");
        } else {
            $(".fixedContainerMembers").show("fast", function () {
                $("#page").toggleClass("mobileOpen");
                $(".menuContainerMembers").toggleClass("active");
                $(".menuContainerMembers .menu").toggleClass("show");

                $(".mobileMemberMenu").toggleClass("open");
            });
        }
    });

    $("body").on("click", ".menu .toplink>a", function (event) {
        event.preventDefault();

        $(this).parent().toggleClass("opened");
        $(this).parent().children(".sub-menu").toggleClass("open");
        $(this).parent().children(".sub-menu").slideToggle();
    });

    $("body").on("click", ".menu .category>a", function (event) {
        event.preventDefault();

        $(this).parent().toggleClass("opened");
        $(this).parent().children(".sub-menu").toggleClass("open");
        $(this).parent().children(".sub-menu").slideToggle();
    });

    //youtube API

    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Init empty array of iframe IDs, one from each video
    var iframeIds = [];
    var iframes = "";

    // For each iframe you find, add its ID to the iframeIds array
    if ($(".js-youtube-iframe").length > 0) {
        var iframes = document.querySelectorAll(".js-youtube-iframe");
        iframes.forEach(function (iframe) {
            iframeIds.push(iframe.id);
        });
    }

    // Once the YouTube API is ready, for each iframeId in your array, create
    // a new YT player and give it the onReady event

    function onYouTubeIframeAPIReady() {
        iframeIds.forEach(function (iframeId) {
            var player = new YT.Player(iframeId, {
                events: {
                    onReady: onPlayerReady,
                },
            });
        });
    }

    // Init empty array of iframe YT objects for use elsewhere
    // Here I only use this to iterate through and pause all videos when
    // another begins playing
    var iframeObjects = [];

    // Shared onReady event which adds events to each video's corresponding
    // play and stop buttons
    function onPlayerReady(event) {
        var iframeObject = event.target;
        var iframeElement = iframeObject.a;
        var videoContainer = iframeElement.parentElement;
        var play = videoContainer.querySelector(".play");
        var stop = videoContainer.querySelector(".stop");

        // Push current iframe object to array
        iframeObjects.push(iframeObject);

        play.addEventListener("click", function () {
            // Pause all videos currently playing
            iframeObjects.forEach(function (scopediframeObject) {
                scopediframeObject.pauseVideo();
                var scopediframeElement = scopediframeObject.a;
                scopediframeElement.classList.remove("isPlaying");
            });

            // Play selected video
            iframeObject.playVideo();
            iframeElement.classList.add("isPlaying");
        });

        stop.addEventListener("click", function () {
            iframeObject.pauseVideo();
            iframeElement.classList.remove("isPlaying");
        });
    }

    //closing youtube modal to stop all videos
    if ($(".youtubeModal").length > 0) {
        var modals = document.querySelectorAll(".youtubeModal");
        var modalIds = [];

        modals.forEach(function (modal) {
            modalIds.push(modal.id);
        });

        modalIds.forEach(function (id) {
            var myModalEl = document.getElementById(id);
            myModalEl.addEventListener("hidden.bs.modal", function (event) {
                var videos = document.querySelectorAll(".js-youtube-iframe");
                Array.prototype.forEach.call(videos, function (video) {
                    if (video.tagName.toLowerCase() === "video") {
                        video.pause();
                    } else {
                        var src = video.src;
                        video.src = src;
                    }
                });
            });
        });
    }

    //Cart QTY increment
    $("body").on(
        "click",
        ".quantity button.plus, .quantity button.minus",
        function (event) {
            var qty = $(this).parent().find(".qty");
            var val = parseFloat(qty.val());
            var max = parseFloat(qty.attr("max"));
            var min = parseFloat(qty.attr("min"));
            var step = parseFloat(qty.attr("step"));

            if ($(this).is(".plus")) {
                if (max && max <= val) {
                    qty.val(max);
                } else {
                    qty.val(val + step).change();
                }
            } else {
                if (min && min >= val) {
                    qty.val(min);
                } else if (val > 1) {
                    qty.val(val - step).change();
                }
            }
        }
    );

    //product filter open and close

    $("body").on("click", ".filterView", function (event) {
        event.preventDefault();

        $(".filterView").toggleClass("openFilter");
        $(".filterOptions").toggleClass("openFilter");
    });

    function getPosition(element) {
        var yPosition = 0;

        yPosition = $(element).offset().top - $(window).scrollTop();
        return yPosition;
    }

    $(window).on("scroll", function () {
        $(".block-page_anchor").each(function (i, obj) {
            if (getPosition(obj) > 100 && getPosition(obj) < 190) {
                var name = $(this).children().attr("name");

                $(".pageNavAccordion").html(name);
            }
        });
    });

    $("body").on(
        "click",
        ".mobilepageNav .accordion-body .scrollTo",
        function (event) {
            event.preventDefault();
            console.log("skip");
            $(".accordion-collapse").removeClass("show");
        }
    );

    //bike model page navigation banner
    if ($("#pageNav").length > 0) {
        var header = document.querySelector("#header");
        var headerHeight = header.offsetHeight;
        var distanceToTop = $("#navBar").offset().top - $(window).scrollTop();

        var pageNav = document.querySelector("#navBar");
        var pageNavHeight = pageNav.offsetHeight;

        var combinedHeight = headerHeight + pageNavHeight;

        if (distanceToTop <= headerHeight) {
            $("#navBar, #navBarMobile").addClass("fixed");
        }

        $(window).on("scroll", function () {
            var distance =
                $("#navBar, #navBarMobile").offset().top -
                $(window).scrollTop();
            var distance2 =
                $(".block-intro_two_column").offset().top -
                $(window).scrollTop();

            if (distance < headerHeight) {
                $("#navBar, #navBarMobile").addClass("fixed");
            }

            // console.log(distance2);
            if ($("#navBar, #navBarMobile").hasClass("fixed")) {
                if (distance2 > headerHeight) {
                    $("#navBar, #navBarMobile").removeClass("fixed");
                }
            }
        });

        //animate scroll to anchor
        function scrollToAnchor(name) {
            var aTag = $("a[name='" + name + "']");
            $("html,body").animate(
                { scrollTop: aTag.offset().top - combinedHeight },
                "slow"
            );
        }

        $("body").on("click", ".scrollTo", function (event) {
            event.preventDefault();
            var name = $(this).attr("data-name");
            scrollToAnchor(name);
        });
    }

    //on scroll from top animate header lines
    var el = $(".shapeBL .line");
    var scroll = 250;

    $(window).on("scroll", function () {
        if ($(this).scrollTop() > scroll) {
            el.addClass("hide");
        } else {
            el.removeClass("hide");
        }
    });

    window.addEventListener("load", function () {
        var el3 = $("footer .line");
        var scroll3 = $(document).height() - $(window).height() - 100;
        $(window).on("scroll", function () {
            if ($(this).scrollTop() > scroll3) {
                el3.removeClass("hidden");
            } else {
                el3.addClass("hidden");
            }
        });
    });

    //on scroll from top animate header lines
    function resize(el2, scroll2) {
        $(window).on("scroll", function () {
            if ($(this).scrollTop() > scroll2) {
                el2.removeClass("hidden");
            } else {
                el2.addClass("hidden");
            }
        });
    }

    $(window).on("resize", function () {
        var el2 = $("footer .line");
        var scroll2 = $(document).height() - $(window).height() - 100;
        resize(el2, scroll2);
    });

    // SWIPER JS

    //for every slider with class loop around same elements on page

    if ($(".threeSlider-wrapper").length > 0) {
        const threeSlider = document.querySelectorAll(".threeSlider-wrapper");

        for (i = 0; i < threeSlider.length; i++) {
            var first = threeSlider[i].nextElementSibling;
            var second = first.nextElementSibling;
            var third = second.nextElementSibling;

            threeSlider[i].classList.add("threeSlider-wrapper-" + i); //slider
            first.classList.add("swiper-pagination-" + i); //pagination
            second.classList.add("three-swiper-button-next-" + i); //next
            third.classList.add("three-swiper-button-prev-" + i); //previous

            var slider = new Swiper(".threeSlider-wrapper-" + i, {
                speed: 1000,
                slidesPerView: 3,
                spaceBetween: 30,
                breakpoints: {
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                },
                direction: "horizontal",
                loop: true,
                autoplay: false,
                pagination: {
                    el: ".swiper-pagination-" + i,
                    type: "bullets",
                },
                navigation: {
                    nextEl: ".three-swiper-button-next-" + i,
                    prevEl: ".three-swiper-button-prev-" + i,
                },
            });
        }
    }

    if ($("#woo-product .productSwiper").length > 0) {
        //product swiper
        var productSwiper = new Swiper("#woo-product .productSwiper", {
            spaceBetween: 10,
            slidesPerView: 3,
            freeMode: false,
            watchSlidesProgress: true,
            allowTouchMove: false,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                300: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
            },
        });
    }

    if ($("#woo-product .productSwiper2").length > 0) {
        var productSwiper2 = new Swiper("#woo-product .productSwiper2", {
            spaceBetween: 10,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            navigation: {
                nextEl: "#woo-product .product-swiper-button-next",
                prevEl: "#woo-product .product-swiper-button-prev",
            },
            thumbs: {
                swiper: productSwiper,
            },
        });
    }

    if ($(".button-wrapper").length > 0) {
        var buttonswiper = new Swiper(".button-wrapper", {
            speed: 1000,
            slidesPerView: 1,
            direction: "horizontal",
            loop: false,
            autoplay: false,
            noSwiping: true,
            noSwipingClass: "button-wrapper",
            onlyExternal: true,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            navigation: {
                nextEl: ".bikeSelection-swiper-button-next",
                prevEl: ".bikeSelection-swiper-button-prev",
            },
        });
    }

    if (
        $(".bikeSelection-wrapper").length > 0 &&
        $(".bikeBrandSelection-wrapper").length > 0
    ) {
        var bikebrandswiper = new Swiper(".bikeBrandSelection-wrapper", {
            speed: 1200, //actually set in css
            slidesPerView: 1,
            direction: "horizontal",
            loop: false,
            autoplay: false,
            noSwiping: true,
            noSwipingClass: "bikeBrandSelection-wrapper",
            onlyExternal: true,
        });
        var bikeswiper = new Swiper(".bikeSelection-wrapper", {
            speed: 1000,
            on: {
                slideChange: function () {
                    const slide = bikeswiper.activeIndex;
                    $(".breadcrumbSlide").removeClass("active");
                    $(".breadcrumbSlide[data-slide=" + slide + "]").addClass(
                        "active"
                    );
                },
            },
            slidesPerView: 1,
            direction: "horizontal",
            loop: false,
            autoplay: false,
            navigation: {
                nextEl: ".bikeSelection-swiper-button-next",
                prevEl: ".bikeSelection-swiper-button-prev",
            },
        });

        //to have seperate speed use css to change transition duration and set to important
        bikeswiper.controller.control = [bikebrandswiper];
    }

    //color options

    if (
        $(".bikeColor-wrapper").length > 0 &&
        $(".bikeThumbSelection-wrapper").length > 0
    ) {
        var bikethumbswiper = new Swiper(".bikeThumbSelection-wrapper", {
            speed: 1200, //actually set in css
            slidesPerView: 1,
            direction: "horizontal",
            loop: false,
            autoplay: false,
            noSwiping: true,
            noSwipingClass: "bikeThumbSelection-wrapper",
            onlyExternal: true,
        });

        var bikeColorswiper = new Swiper(".bikeColor-wrapper", {
            speed: 1000,
            on: {
                slideChange: function () {
                    const slide = bikeColorswiper.activeIndex;
                    $(".breadcrumbSlideColor").removeClass("active");
                    $(
                        ".breadcrumbSlideColor[data-slide=" + slide + "]"
                    ).addClass("active");
                },
            },
            slidesPerView: 1,
            direction: "horizontal",
            loop: false,
            autoplay: false,
            navigation: {
                nextEl: ".bikeColorSelection-swiper-button-next",
                prevEl: ".bikeColorSelection-swiper-button-prev",
            },
        });

        bikeColorswiper.controller.control = [bikethumbswiper];
    }

    //thumb selection
    $("body").on("click", ".itemBike", function (event) {
        event.preventDefault();
        var id = $(this).attr("data-id");
        var currentSlide = $(this).attr("data-active");

        $(".item").removeClass("active");
        $(this).addClass("active");

        $(".bikeColor" + currentSlide).fadeOut();

        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: "thumb",
                id: id,
            },
            dataType: "json",
            encode: true,
        })
            .done(function (response) {
                //console.log(response.image);
                $(".bikeColor" + currentSlide).html(response.image);
                $(".bikeColor" + currentSlide).fadeIn();
            })
            .fail(function () {})
            .always(function () {});
    });

    //preconfiged bikes select ajax

    $("body").on("change", ".preconfigBuy", function (event) {
        event.preventDefault();
        var val = this.value;
        var id = $(this).attr("data-postid");

        //keeps existing height
        var height = $(".ajaxResponsePreconfig").outerHeight();
        $(".ajaxResponsePreconfig").css("min-height", height);

        $(".ajaxResponsePreconfig .attributes").fadeOut("fast");

        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: "preconfig",
                val: val,
                id: id,
            },
            dataType: "json",
            encode: true,
        })
            .done(function (response) {
                $(".ajaxResponsePreconfig").hide();
                $(".ajaxResponsePreconfig").html(response.html);
                $(".ajaxResponsePreconfig").fadeIn("fast");
                //force price into button value
                $("#passedPrice").val(response.price);
                $("#passedName").val(response.name);
            })
            .fail(function () {})
            .always(function () {});
    });

    //copy to clipboard
    $("body").on("click", ".copyToClipboard", function (event) {
        event.preventDefault();
        var copyText = document.getElementById("copyClip").innerText;

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText);
    });

    //a link to submit form on pay deposit
    $("body").on("click", "#depositForm .bikePriceConfig", function (event) {
        event.preventDefault();
        this.closest("form").submit();
        return false;
    });

    $("body").on("click", "#updateForm .updateRef", function (event) {
        event.preventDefault();
        this.closest("form").submit();
        return false;
    });

    if ($(".featureSliderText").length > 0 && $(".featureSlider").length > 0) {
        var featureswiperText = new Swiper(".featureSliderText", {
            speed: 1200,
            slidesPerView: 1,
            direction: "horizontal",
            loop: true,
            autoplay: false,
            noSwiping: true,
            noSwipingClass: "featureSliderText",
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
        });

        var featureswiper = new Swiper(".featureSlider", {
            speed: 1200,
            slidesPerView: 1,
            direction: "horizontal",
            loop: true,
            autoplay: false,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
            },
            navigation: {
                nextEl: ".feature-swiper-button-next",
                prevEl: ".feature-swiper-button-prev",
            },
        });

        // featureswiperText.controller.control = featureswiper;
        featureswiper.controller.control = featureswiperText;
    }

    $("body").on("click", ".breadcrumbSlideColor", function (event) {
        event.preventDefault();

        $(".breadcrumbSlideColor").removeClass("active");
        $(this).addClass("active");

        var num = $(this).attr("data-slide");
        bikeColorswiper.slideTo(num);
    });

    $("body").on("click", ".breadcrumbSlide", function (event) {
        event.preventDefault();

        $(".breadcrumbSlide").removeClass("active");
        $(this).addClass("active");

        var num = $(this).attr("data-slide");
        bikebrandswiper.slideTo(num);
        bikeswiper.slideTo(num);
        buttonswiper.slideTo(num);
    });

    //ANIMATION FUNCTIONS

    function animation(animation, elementAnimate, mycallback) {
        if (animation == "animateRight") {
            // elementAnimate.classList.add('showRight');
            // waitForElementTransition(elementAnimate).then(() => {
            // 	mycallback();
            // });
            mycallback();
        }

        if (animation == "animateLeft") {
            // elementAnimate.classList.add('showLeft');
            // waitForElementTransition(elementAnimate).then(() => {
            // 	mycallback();
            // });
            mycallback();
        }
    }

    function animationOut(animation, elementAnimate, mycallback) {
        // if(animation == 'animateRight'){
        // 	elementAnimate.classList.remove('showRight');
        // 	mycallback();
        // }
        mycallback();

        // if(animation == 'animateLeft'){
        // 	elementAnimate.classList.remove('showLeft');
        // 	mycallback();
        // }
        mycallback();
    }

    //INTERSECT OBSERVER
    let lineAnimateoptions = {
        //root: document.querySelector(''),
        rootMargin: "10px",
        threshold: 0.95,
    };

    let lineAnimateoptions2 = {
        //root: document.querySelector(''),
        rootMargin: "10px",
        threshold: 0,
    };

    // using instersect observer
    const lineAnimateObserver2 = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const lines = entry.target.children[0].children;
            const childrenNodeArr = Array.from(lines);

            //check if object is intersecting

            if (entry.isIntersecting == false) {
                const elemFound = childrenNodeArr.find((e) =>
                    e.hasAttribute("data-tessellate")
                );

                if (elemFound != null) {
                    //animate this first
                    let animateType = elemFound.getAttribute("data-tessellate");

                    //function to do animation
                    animationOut(animateType, elemFound, function () {
                        for (let item of lines) {
                            let animationElement = item.firstElementChild;

                            if (animationElement != null) {
                                //change animation time to 0s
                                animationElement.style.transition =
                                    "all 0s ease";
                                animationElement.classList.remove("show");
                            }
                        }
                    });
                } else {
                    for (let item of lines) {
                        let animationElement = item.firstElementChild;

                        if (animationElement != null) {
                            animationElement.classList.remove("show");
                        }
                    }
                }

                return;
            }
        });
    }, lineAnimateoptions2);

    // using instersect observer
    const lineAnimateObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const lines = entry.target.children[0].children;
            const childrenNodeArr = Array.from(lines);

            //check if object is intersecting
            if (entry.isIntersecting) {
                //checks in data attribut exists with child elements
                const elemFound = childrenNodeArr.find((e) =>
                    e.hasAttribute("data-tessellate")
                );

                if (elemFound != null) {
                    //animate this first
                    let animateType = elemFound.getAttribute("data-tessellate");

                    //function to do animation
                    animation(animateType, elemFound, function () {
                        for (let item of lines) {
                            let animationElement = item.firstElementChild;

                            if (animationElement != null) {
                                if (animationElement.className == "fade-in") {
                                    //change animation time to 0.6s
                                    animationElement.style.transition =
                                        "all 0.6s ease";
                                    animationElement.classList.add("show");
                                }
                            }
                        }
                    });
                } else {
                    for (let item of lines) {
                        let animationElement = item.firstElementChild;

                        if (animationElement != null) {
                            if (animationElement.className == "fade-in") {
                                animationElement.classList.add("show");
                            }
                        }
                    }
                }

                return;
            }
        });
    }, lineAnimateoptions);

    var lineAnimations = document.querySelectorAll(
        '[data-tessellate="animateLines"]'
    );
    for (var i = 0; i < lineAnimations.length; i++) {
        lineAnimateObserver.observe(lineAnimations.item(i));
        lineAnimateObserver2.observe(lineAnimations.item(i));
    }

    // INTERSECT OBSERVE DONUT CHART

    //intersect observer for donut chart
    let donutAnimateoptions = {
        //root: document.querySelector(''),
        rootMargin: "10px",
        threshold: 0.95,
    };

    const donutAnimateObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const svg = entry.target.children[0].children[1];

            const json = JSON.parse(entry.target.dataset["json"]);

            //console.log(json)

            var val1 = json["data1"];
            var val2 = json["data2"];
            var val3 = json["data3"];

            var endpoint = 6.3;
            var lastpoint = val3 - endpoint;

            var total = val1 + val2;

            //Dark Grey Segment
            const first = svg.children[5];
            const firstCurve = svg.children[6];

            //Gold Segment
            const second = svg.children[7];
            const secondCurve = svg.children[8];

            //grey Segment
            const third = svg.children[3];
            const thirdCurve = svg.children[4];

            //last Segment
            const last = svg.children[9];
            const lastCurve = svg.children[10];

            //check if object is intersecting
            if (entry.isIntersecting) {
                function animateCircle(secondCurve, second, mycallback) {
                    $(secondCurve).css("stroke-dashoffset", "-" + val1);
                    $(second).css("stroke-dasharray", val1 + " 100");
                    second.addEventListener("transitionend", () => {
                        mycallback();
                    });
                }

                animateCircle(secondCurve, second, function () {
                    $(firstCurve).css({
                        "stroke-dashoffset": "-" + total + "",
                        opacity: 1,
                    });
                    $(first).css({
                        "stroke-dasharray": val2 + " 100",
                        opacity: 1,
                    });

                    // $(thirdCurve).css("stroke-dashoffset", "-"+val2);
                    // $(third).css("stroke-dasharray", val2+" 100");

                    first.addEventListener("transitionend", () => {
                        $(thirdCurve).css({
                            "stroke-dashoffset": "-93.7",
                            opacity: 1,
                        });
                        $(third).css({
                            "stroke-dasharray": lastpoint + " 100",
                            opacity: 1,
                        });
                        third.addEventListener("transitionend", () => {
                            $(lastCurve).css("opacity", "1");
                            $(last).css("opacity", "1");
                            $(thirdCurve).css("opacity", "0");
                            thirdCurve.addEventListener("transitionend", () => {
                                $(lastCurve).css("stroke-dashoffset", "-100");
                                $(last).css("stroke-dasharray", "6.3 100");
                            });
                        });
                    });
                });

                return;
            }
        });
    }, donutAnimateoptions);

    var donutAnimation = document.querySelectorAll(
        '[data-tessellate="animateDonut"]'
    );
    for (var i = 0; i < donutAnimation.length; i++) {
        donutAnimateObserver.observe(donutAnimation.item(i));
    }

    //product variation
    $(".single_variation_wrap").on(
        "show_variation",
        function (event, variation) {
            //get image from
            let productImage = variation.image_id;

            let status = "";

            // console.log(variation)
            var statusDiv = $("#statusDiv");

            if (statusDiv.length) {
                if (variation.is_in_stock) {
                    if (variation.availability_html != "") {
                        $(".instockDiv").hide();
                        $(".soldoutDiv").hide();
                        $(".backorderDiv").show();
                        $(".available").show();
                    } else {
                        $(".backorderDiv").hide();
                        $(".soldoutDiv").hide();
                        $(".instockDiv").show();
                        $(".available").show();
                    }
                } else {
                    $(".soldoutDiv").show();
                    $(".backorderDiv").hide();
                    $(".instockDiv").hide();
                    $(".available").hide();
                }
            }

            $.ajax({
                type: "POST",
                url: site_data.adminajax,
                data: {
                    action: "get_image",
                    imageId: productImage,
                },
                dataType: "json",
                encode: true,
            })
                .done(function (response) {
                    //console.log(response.html);
                    console.log(response.image);

                    // reinit slider

                    $(".productFisrtImage").html(response.image);

                    //productSwiper2.reInit()
                })
                .fail(function () {})
                .always(function () {});
        }
    );

    // Consent Magic custom modal link
    $("body").on("click", ".tessellateConsent", function (event) {
        event.preventDefault();
        $(".cs-info-sticky-button").click();
    });
});
